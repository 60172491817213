import * as yup from "yup";

export const createUserSchema = () => yup.object().shape({
    studioName: yup.string().min(5).max(80).required(),
    city: yup.string().max(100).required(),
    countryCode: yup.string().required(),
    subregionId: yup.string().required(),
    email: yup.string().email().max(200).required(),
    emailConfirmation: yup.string().email().max(200)
        .oneOf([yup.ref('email'), null]).required(),
    phone: yup.string().max(50).required(),
    kuerzel: yup.string().min(2).max(8).required(),
    termsAgreed: yup.boolean().isTrue().required()
})

export const createNewUser = () => ({
    studioName: '',
    city: '',
    countryCode: 'DE',
    subregionId: '',
    phone: '',
    kuerzel: ''
})


import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import HttpApi from 'i18next-http-backend';
import {i18_TRANSLATION} from "./i18nReferences";

i18n
    .use(initReactI18next)
    .use(HttpApi)
    .init({
            ns: [i18_TRANSLATION],
            defaultNS: i18_TRANSLATION,
        lng: 'de',
        debug: (process.env.REACT_APP_ENV === 'development'),
        fallbackLng: 'en',
        interpolation: {escapeValue: false},
        keySeparator: false
    });

export default i18n;

import axios from "axios";
import {BACKEND} from "../config/backend";
import {generateSecretFromObject} from "../utils/generateSecretFromObject";

export const getAllUserNames = async () => {
    const response = await axios.get(`${BACKEND}/api/v1/users/names`)
    return response?.data
}

export const registerUser = async (data) => {
    const payload = {...data, secret: generateSecretFromObject(data)}
    await axios.post(`${BACKEND}/api/v1/users`, payload)
}
export const TERMS_OF_USE_LINK = `${BACKEND}/pdf/Nutzungsvereinbarung.pdf`

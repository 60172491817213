import React from 'react';
import {FormControl, FormHelperText, InputLabel, MenuItem, Select} from "@mui/material";
import {Controller} from "react-hook-form";


const ControlledSelection = ({
                                  label,
                                  control,
                                  selections,
                                  controllerName,
                                  canEdit = true,
                                  labelId,
                                  onChange,
                                  error,
                                  helperText,
                                  fullWidth,
                                  autoFocus,
                                  ...rest
                              }) => {
    return (
        <React.Fragment>
            <Controller name={controllerName}
                        control={control}
                        defaultValue=''
                        render={({field}) =>
                            <FormControl variant="standard" sx={{minWidth: '100px'}} error={error}
                                         fullWidth={fullWidth} {...rest}>
                                <InputLabel id={labelId}>{label}</InputLabel>
                                <Select {...field} autoFocus={autoFocus}
                                        disabled={!canEdit}
                                        onChange={(e) => {
                                            field.onChange(e)
                                            onChange && onChange(e.target.value)
                                        }}
                                        labelId={labelId}
                                >
                                    {selections.map((t) => <MenuItem key={t.id} value={t.id}>{t.text}</MenuItem>)}
                                </Select>
                                {helperText && <FormHelperText>{helperText}</FormHelperText>}
                            </FormControl>
                        }
            />
        </React.Fragment>
    )
}

export default ControlledSelection;

import React from "react";
import {createRoot} from 'react-dom/client';
import './i18n';
import './yupLocalization';
import AppContainer from "./components/AppContainer";

const container = document.querySelector('#root')
const root = createRoot(container)

root.render(<React.StrictMode><AppContainer /></React.StrictMode>);

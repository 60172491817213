import React, {useCallback, useState} from 'react';
import {AppBar, Box, FormControl, IconButton, MenuItem, Select, Toolbar, Typography} from "@mui/material";
import logoLight from "../public/images/KissCalLogoWithText-light.png";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import {useTranslation} from "react-i18next";
import {i18_TRANSLATION} from "../i18nReferences";
import i18n from "i18next";

const StartToolbar = ({mode, onToggleMode}) => {
    const [lang, setLang] = useState('de')
    const {t} = useTranslation([i18_TRANSLATION])

    const handleChangeLocale = useCallback(locale => {
        console.log('changing language')
        i18n.changeLanguage(locale)
        setLang(locale)
    }, [])
    return (
        <AppBar position="sticky">
            <Toolbar disableGutters>
                <Box display="flex" width="100%" alignItems="center">
                    <Box flex="1" padding={1}>
                        <Box sx={{height: {xs: "3em", sm: "4em", md: "53m"}, display: "flex"}}>
                            <img height="100%" src={logoLight} alt="kisscal-logo"/>
                        </Box>
                        <Typography variant="h6" sx={{display: {xs: "none", md: "flex"}}}>{t('TOOLBAR_SUBTITLE')}</Typography>
                    </Box>
                    <Box flex="0 0 Auto">
                        <FormControl variant="standard">
                            <Select disableUnderline
                                    id="demo-simple-select"
                                    value={lang || ''}
                                    label="Age"
                                    onChange={(e) => handleChangeLocale(e.target.value)}
                                    inputProps={{color: 'white'}}
                            >
                                <MenuItem value="en">🇬🇧&nbsp;en</MenuItem>
                                <MenuItem value="de">🇩🇪&nbsp;de</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                    <Box flex="0 0 Auto">
                        <IconButton color="inherit" onClick={onToggleMode}>
                            {mode === 'dark' ? <Brightness7Icon/> : <Brightness4Icon/>}
                        </IconButton>
                    </Box>
                </Box>
            </Toolbar>
            <Box padding={1} sx={{display: {xs: "flex", md: "none"}}}>
                <Typography variant="h6">{t('TOOLBAR_SUBTITLE')}</Typography>
            </Box>

        </AppBar>
    );
};

export default StartToolbar;

import {sha256} from "js-sha256";

export function generateSecretFromObject(object) {
    const testObject = {...object}
    testObject.__salt = ['s', 'a', 'l','t'].join('');
    // Sort the keys of the object
    const sortedKeys = Object.keys(testObject).sort();

    // Create a new object with sorted keys
    const sortedObject = {};
    sortedKeys.forEach(key => {
        sortedObject[key] = testObject[key];
    });

    // Convert the sorted object to a JSON string
    const jsonString = JSON.stringify(sortedObject);

    // Use the sha256 function from js-sha256 library to produce a hash value
    // Return the hash as the secret
    return sha256(jsonString);
}

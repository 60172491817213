import React from 'react';
import {Alert, Container, Paper} from "@mui/material";
import errorImage from '../public/images/error.png'
import {useTranslation} from "react-i18next";

const Error = ({errorText}) => {
    const {t} = useTranslation()
    return (
        <Container maxWidth="md">
            <Paper>
                <img width="100%" src={errorImage} alt="error-image"/>
                <Alert severity="error">
                    {errorText && t(errorText) || t('ERROR.INFO')}
                </Alert>
            </Paper>
        </Container>
    );
};

export default Error;

import React from 'react';
import {Alert, Container, Box, Link} from "@mui/material";
import {useTranslation} from "react-i18next";
import successImage from '../public/images/kisscal-erfolg.jpg'

const Success = () => {
    const {t} = useTranslation()
    return (
        <Container maxWidth="md">
            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                <img width="100%" src={successImage} alt="success-image"/>
                <Alert severity="success">
                    {t('SUCCESS.INFO_TEXT')}
                </Alert>
                <Link href={process.env.KISSCAL_APP_URL} target="_blank">
                    {t('SUCCESS.KISSCAL_LINK_TEXT')}
                </Link>
            </Box>
        </Container>
    );
};

export default Success;
